/** 
 * 防抖适用场景：搜索框
 * @param {function} handler 进行防抖的函数
 * @param {number} delay 等待时间ms
 */
export const debounceFn = function() {
     var timer = null;
     return function (handler, delay = 1000) {
          clearTimeout(timer);
          timer = setTimeout(function () {
          handler && handler();
          }, delay);
     }
}