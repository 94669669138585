import request from './request/request'

// 图片上传
export function imgUpload(data){
    return request({
        url: `/picture/upload`,
        method: 'post',
        data
        
    })
}

export function addOpinionCase(data){
    return request({
        url: '/opinionCase/create',
        method: 'post',
        data
    })
}

export function addThumbsUp(data){
    return request({
        url: '/thumbsUp/create',
        method: 'post',
        data
    })
}

export function addFindLeader(data){
    return request({
        url: '/findLeader/create',
        method: 'post',
        data
    })
}